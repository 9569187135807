import Plugin from 'core/plugins/plugin'
import { AppPlugins } from 'app/constants'
import { metal3Logo } from './logo'

import Overview from './components/overview'

const metal3Plugin = new Plugin(AppPlugins.Metal3, 'Metal³', '/ui/metal3', metal3Logo)
metal3Plugin.setOption('singlePane', true)

metal3Plugin.registerRoutes([
  {
    name: 'Overview',
    link: { path: '/overview', exact: true, default: true },
    component: Overview,
  },
])

const navItems = [
  {
    name: 'Overview',
    link: { path: '/overview' },
    icon: 'tachometer',
  },
]

metal3Plugin.registerNavItems(navItems)

export default metal3Plugin
